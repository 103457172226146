<template>
  <div class="tw-container border border-1 border-dark p-3">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20">供應商</p>
    <hr />
    <div class="mb-3">
      <button
        class="tw-btn tw-btn-success"
        @click="showModal('createSupplier')"
      >
        新增供應商
      </button>
    </div>

    <!-- 表格 -->
    <div
      class="table-responsive"
      style="height: calc(100vh - 187px); overflow-y: scroll"
    >
      <table class="table table-hover">
        <thead class="float-header">
          <tr>
            <th style="min-width: 50px">移動</th>
            <th style="min-width: 150px">名稱</th>
            <th style="min-width: 80px">銀行帳號</th>
            <th style="min-width: 100px">聯絡人</th>
            <th style="min-width: 100px">國籍</th>
            <th style="min-width: 150px">地址</th>
            <th style="min-width: 100px">電話</th>
            <th style="min-width: 200px">備註</th>
            <th style="min-width: 115px">操作</th>
          </tr>
        </thead>
        <Draggable
          v-model="suppliers"
          class="draggable"
          @start="drag = true"
          @end="drag = false"
          item-key="id"
          tag="tbody"
          handle=".handle"
        >
          <template #item="{ element }">
            <tr>
              <td class="handle pointer">
                <i class="bi bi-arrows-move"></i>
              </td>
              <td :id="element.id" class="text-break">
                {{ element.name }}
              </td>
              <td class="text-break">{{ element.bankAccount }}</td>
              <td class="text-break">{{ element.contactPerson }}</td>
              <td class="text-break">{{ element.country }}</td>
              <td class="text-break">{{ element.address }}</td>
              <td class="text-break">{{ element.phoneNumber }}</td>
              <td class="text-break">{{ element.note }}</td>
              <td>
                <button
                  class="tw-btn tw-btn-success me-2"
                  @click="showModal('editSupplier', element)"
                >
                  編輯
                </button>
                <button
                  class="tw-btn tw-btn-danger"
                  @click="showModal('delSupplier', element.id)"
                >
                  刪除
                </button>
              </td>
            </tr>
          </template>
        </Draggable>
      </table>
    </div>
  </div>
  <!-- 新增供應商 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="createSupplierModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增供應商</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 供應商名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierName"
              placeholder="輸入供應商名稱(20字)"
              maxlength="20"
              v-model="supplierData.name"
            />
            <label for="CreateSupplierName" class="text-danger"
              >*輸入供應商名稱(20字)</label
            >
          </div>
          <!-- 銀行帳戶 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierBankAccount"
              placeholder="輸入銀行帳戶(30字)"
              maxlength="30"
              v-model="supplierData.bankAccount"
            />
            <label for="CreateSupplierBankAccount">輸入銀行帳戶(30字)</label>
          </div>
          <!-- 聯絡人 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierContactPerson"
              placeholder="輸入聯絡人(15字)"
              maxlength="15"
              v-model="supplierData.contactPerson"
            />
            <label for="CreateSupplierContactPerson">輸入聯絡人(15字)</label>
          </div>
          <!-- 國家 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierContry"
              placeholder="輸入國家(20字)"
              maxlength="20"
              v-model="supplierData.country"
            />
            <label for="CreateSupplierContry">輸入國家(20字)</label>
          </div>
          <!-- 輸入地址 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierAddress"
              placeholder="輸入地址(30字)"
              maxlength="30"
              v-model="supplierData.address"
            />
            <label for="CreateSupplierAddress">輸入地址(30字)</label>
          </div>
          <!-- 輸入電話 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CreateSupplierTel"
              placeholder="輸入電話(15字)"
              maxlength="15"
              v-model="supplierData.phoneNumber"
            />
            <label for="CreateSupplierTel">輸入電話(15字)</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              rows="3"
              id="CreateSupplierNote"
              style="height: 100px"
              maxlength="300"
              v-model="supplierData.note"
            ></textarea>
            <label for="CreateSupplierNote">備註:(300字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createSupplier"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯供應商 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editSupplierModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯供應商</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 供應商名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierName"
              placeholder="輸入供應商名稱(20字)"
              maxlength="20"
              v-model="supplierData.name"
            />
            <label for="EditSupplierName" class="text-danger"
              >*輸入供應商名稱(20字)</label
            >
          </div>
          <!-- 銀行帳戶 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierBankAccount"
              placeholder="輸入銀行帳戶(30字)"
              maxlength="30"
              v-model="supplierData.bankAccount"
            />
            <label for="EditSupplierBankAccount">輸入銀行帳戶(30字)</label>
          </div>
          <!-- 聯絡人 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierContactPerson"
              placeholder="輸入聯絡人(15字)"
              maxlength="15"
              v-model="supplierData.contactPerson"
            />
            <label for="EditSupplierContactPerson">輸入聯絡人(15字)</label>
          </div>
          <!-- 國家 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierContry"
              placeholder="輸入國家(20字)"
              maxlength="20"
              v-model="supplierData.country"
            />
            <label for="EditSupplierContry">輸入國家(20字)</label>
          </div>
          <!-- 輸入地址 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierAddress"
              placeholder="輸入地址(30字)"
              maxlength="30"
              v-model="supplierData.address"
            />
            <label for="EditSupplierAddress">輸入地址(30字)</label>
          </div>
          <!-- 輸入電話 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditSupplierTel"
              placeholder="輸入電話(15字)"
              maxlength="15"
              v-model="supplierData.phoneNumber"
            />
            <label for="EditSupplierTel">輸入電話(15字)</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              rows="3"
              id="EditSupplierNote"
              style="height: 100px"
              maxlength="300"
              v-model="supplierData.note"
            ></textarea>
            <label for="EditSupplierNote">備註:(300字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editSupplier"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除供應商 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delSupplierModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除供應商
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-danger fw-bolder">是否確定刪除?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-danger"
            @click="delSupplier"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  components: {Draggable},
  data() {
    return {
      // modal
      createSupplierModal: {},
      editSupplierModal: {},
      delSupplierModal: {},
      // data
      serverToken: '',
      storeId: 0,
      suppliers: [],
      supplierDetailId: [],
      supplierData: {
        name: '',
        bankAccount: '',
        contactPerson: '',
        country: '',
        address: '',
        phoneNumber: '',
        note: '',
      }
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getSuppliers()
  },
  mounted() {
    this.createModals([
      'createSupplierModal','editSupplierModal','delSupplierModal'
    ])
  },
  watch: {
    // 供應商拖曳重新排序
    suppliers: {
      handler(val, oldVal) {
        const saveSequence = val.some((item, index) => {
          return item.sequence !== index
        })
        if (saveSequence) this.updateSupplierSequence()
      },
      deep: true,
    },
    // 電話號碼格式 (新增/編輯供應商)
    'supplierData.phoneNumber'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.supplierData.phoneNumber = val.replace(/[^\d]/g, "")
      }
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'createSupplier') {
        this.supplierData = {
          name: '',
          bankAccount: '',
          contactPerson: '',
          country: '',
          address: '',
          phoneNumber: '',
          note: ''
        }
        this.createSupplierModal.show()
      } else if (status === 'editSupplier') {
        this.supplierData = {
          id: item.id,
          name: item.name,
          bankAccount: item.bankAccount,
          contactPerson: item.contactPerson,
          country: item.country,
          address: item.address,
          phoneNumber: item.phoneNumber ? item.phoneNumber : '',
          note: item.note,
        }
        this.editSupplierModal.show()
      } else if (status === 'delSupplier') {
        console.log(item)
        this.supplierDetailId = [item]
        this.delSupplierModal.show()
      }
    },
    // 取得供應商資料
    getSuppliers() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getSuppliers}'
        },
      ]
      vm.suppliers = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects
            vm.suppliers = storeInfo[0].suppliers
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 新增供應商
    createSupplier() {
      this.$methods.switchLoading('show')
      const vm = this
      const createSupplierApi = `${process.env.VUE_APP_API}/supplier/create`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        storeId: this.storeId,
        name: this.supplierData.name,
        bankAccount: this.supplierData.bankAccount,
        contactPerson: this.supplierData.contactPerson,
        country: this.supplierData.country, 
        address: this.supplierData.address,
        phoneNumber: this.supplierData.phoneNumber,
        note: this.supplierData.note
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: createSupplierApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getSuppliers()
            vm.createSupplierModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 編輯供應商
    editSupplier() {
      this.$methods.switchLoading('show')
      const vm = this
      const editSupplierApi = `${process.env.VUE_APP_API}/supplier/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.supplierData.id,
        name: this.supplierData.name,
        bankAccount: this.supplierData.bankAccount,
        contactPerson: this.supplierData.contactPerson,
        country: this.supplierData.country, 
        address: this.supplierData.address,
        phoneNumber: this.supplierData.phoneNumber,
        note: this.supplierData.note
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editSupplierApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getSuppliers()
            vm.editSupplierModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 刪除供應商
    delSupplier() {
      const vm = this
      this.$methods.switchLoading('show')
      const delSupplierApi = `${process.env.VUE_APP_API}/supplier/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append("ids", this.supplierDetailId)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delSupplierApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getSuppliers()
            vm.delSupplierModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 更新供應商順序
    updateSupplierSequence() {
      const vm = this
      this.$methods.switchLoading('show')
      const reorderSequence = ` ${process.env.VUE_APP_API}/supplier/reorderSequence`;
      const header = {
        authorization: this.serverToken
      }
      let supplierIds = []
      this.suppliers.forEach((item) => {
        supplierIds.push(item.id)
      })
      const data = new FormData()
      data.append("ids", supplierIds)
      $.ajax({
        type: 'PUT',
        async: true,
        url: reorderSequence,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getSuppliers()
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    }
  }
}
</script>